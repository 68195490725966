import {
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewEncapsulation,
} from "@angular/core";

@Component({
  selector: "g-breadcrumb",
  template: `<ng-content></ng-content>
`,
  styles: [`.g-breadcrumb{display:flex}.g-breadcrumb g-breadcrumb-item{margin-right:8px}.g-breadcrumb g-breadcrumb-item:last-child{margin-right:0}.g-breadcrumb g-breadcrumb-item:last-child a,.g-breadcrumb g-breadcrumb-item:last-child span{color:var(--color-grey-600)}.g-breadcrumb g-breadcrumb-item:last-child .g-breadcrumb-separator{display:none}.g-breadcrumb a,.g-breadcrumb span{color:var(--color-primary);text-decoration:none}`],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: "g-breadcrumb",
  },
})
export class BreadcrumbComponent implements OnInit {
  @Input() gSeparator: string | TemplateRef<void>;
  constructor() {}

  ngOnInit() {}
}
