import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { PhoneNumberComponent } from "./phone-number.component";
import { GInputModule } from "../input/input.module";
import { GSelectModule } from "../select/select.module";
import { PhoneNumberCodeComponent } from "./phone-number-code.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

@NgModule({
  declarations: [PhoneNumberComponent, PhoneNumberCodeComponent],
  imports: [
    CommonModule,
    GInputModule,
    GSelectModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  exports: [PhoneNumberComponent, PhoneNumberCodeComponent],
})
export class GPhoneNumberModule {}
