import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MenuComponent } from "./menu.component";
import { MenuTriggerForDirective } from "./menu-trigger-for.directive";
import { OverlayModule } from "@angular/cdk/overlay";
import { MenuItemComponent } from "./menu-item.component";
import { MatRippleModule } from "@angular/material";

@NgModule({
  declarations: [MenuComponent, MenuTriggerForDirective, MenuItemComponent],
  imports: [CommonModule, OverlayModule, MatRippleModule],
  exports: [MenuComponent, MenuTriggerForDirective, MenuItemComponent],
})
export class GMenuModule {}
