import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TypographyComponent } from "./typography.component";

@NgModule({
  declarations: [TypographyComponent],
  exports: [TypographyComponent],
  imports: [CommonModule],
})
export class GTypographyModule {}
