import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TabComponent } from "./tab.component";
import { TabItemComponent } from "./tab-item.component";
import { TabLabelDirective } from "./tab-label.directive";
import { TabHeaderComponent } from "./tab-header.component";
import { GAddOnModule } from "../core/public-api";
import { TabBodyComponent } from "./tab-body.component";
import { GTypographyModule } from "../typography/typography.module";
import { TabBarDirective } from "./tab-bar.directive";
import { TabLinkDirective } from "./tab-link.directive";
import { RouterModule } from "@angular/router";

@NgModule({
  declarations: [
    TabComponent,
    TabItemComponent,
    TabLabelDirective,
    TabHeaderComponent,
    TabBodyComponent,
    TabBarDirective,
    TabLinkDirective,
  ],
  imports: [CommonModule, GAddOnModule, GTypographyModule, RouterModule],
  exports: [
    TabComponent,
    TabItemComponent,
    TabLabelDirective,
    TabHeaderComponent,
    TabBodyComponent,
    TabBarDirective,
    TabLinkDirective,
  ],
})
export class GTabModule {}
