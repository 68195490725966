import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CommasDirective } from "./commas.directive";

@NgModule({
  declarations: [CommasDirective],
  imports: [CommonModule],
  exports: [CommasDirective],
})
export class GCommasModule {}
