import {
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from "@angular/core";

@Component({
  selector: "g-tab-item",
  template: `
    <ng-template #titleTpl>
      <ng-content select="[g-tab-link]"></ng-content>
    </ng-template>
    <ng-template #bodyTpl>
      <ng-content></ng-content>
    </ng-template>
  `,
  styles: [],
})
export class TabItemComponent implements OnInit {
  @Input() label: string | TemplateRef<void>;
  @Input() disabled: boolean;
  @ViewChild("bodyTpl", { static: true }) content: TemplateRef<void>;
  @ViewChild("titleTpl", { static: true }) title: TemplateRef<void>;

  constructor() {}

  ngOnInit() {}
}
