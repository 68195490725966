import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TableComponent } from "./table.component";
import { MatTableModule } from "@angular/material";
import { RouterModule } from "@angular/router";
import { GTypographyModule } from "../typography/typography.module";

@NgModule({
  declarations: [TableComponent],
  imports: [CommonModule, MatTableModule, RouterModule, GTypographyModule],
  exports: [TableComponent],
})
export class GTableModule {}
