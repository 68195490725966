import { DOCUMENT } from "@angular/common";
import {
  Component,
  ElementRef,
  Inject,
  Input,
  OnInit,
  Optional,
} from "@angular/core";
import { IconService } from "./icon.service";

@Component({
  selector: "g-icon",
  template: `<ng-content></ng-content>`,
  host: {
    style:
      "width: 30px; height: 30px; display: inline-flex; border-radius: 50%; overflow: hidden",
  },
  styles: [`svg{width:100%;height:100%;-o-object-fit:cover;object-fit:cover}`],
})
export class IconComponent {
  private svgIcon: SVGElement;

  @Input()
  set name(iconName: string) {
    if (this.svgIcon) {
      this.element.nativeElement.removeChild(this.svgIcon);
    }
    const svgData = this._iconService.getIcon(iconName);
    this.svgIcon = this.svgElementFromString(svgData);
    this.element.nativeElement.appendChild(this.svgIcon);
  }

  constructor(
    private element: ElementRef,
    private _iconService: IconService,
    @Optional() @Inject(DOCUMENT) private document: any
  ) {}

  private svgElementFromString(svgContent: string): SVGElement {
    const div = this.document.createElement("DIV");
    div.innerHTML = svgContent;
    return (
      div.querySelector("svg") ||
      this.document.createElementNS("http://www.w3.org/2000/svg", "path")
    );
  }
}
