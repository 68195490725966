import { Component, OnInit } from "@angular/core";

@Component({
  selector: "g-input-group",
  template: `<ng-content></ng-content>
`,
  styles: [``],
})
export class InputGroupComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
