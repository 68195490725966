import { Component, OnInit } from "@angular/core";

@Component({
  selector: "g-box-content",
  template: `<ng-content></ng-content>`,
  styles: [],
})
export class BoxContentComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
