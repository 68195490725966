import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SelectComponent } from "./select.component";
import { GTypographyModule } from "../typography/typography.module";
import { GClickOutsideModule } from "../directives/click-outside/click-outside.module";
import { MatIconModule } from "@angular/material";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { OptionComponent } from "./option.component";
import { SelectableValueDirective } from "./selectable-value.directive";

@NgModule({
  declarations: [SelectComponent, OptionComponent, SelectableValueDirective],
  exports: [SelectComponent, OptionComponent, SelectableValueDirective],
  imports: [
    CommonModule,
    GTypographyModule,
    GClickOutsideModule,
    MatIconModule,
    FormsModule,
    ReactiveFormsModule,
  ],
})
export class GSelectModule {}
