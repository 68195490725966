import { Directive, Optional, Self } from "@angular/core";
import { RouterLink, RouterLinkWithHref } from "@angular/router";

@Directive({
  selector: "[g-tab-link]",
  host: {
    class: "g-tab-label-link",
  },
})
export class TabLinkDirective {
  constructor(
    @Optional() @Self() public routerLink?: RouterLink,
    @Optional() @Self() public routerLinkWithHref?: RouterLinkWithHref
  ) {}
}
