import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  Renderer2,
  forwardRef,
} from "@angular/core";

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: "[gCommas]",
})
export class CommasDirective {
  private value: string;

  constructor(private elementRef: ElementRef, private renderer: Renderer2) {}
  @Input("gCommas") commas = "";

  @HostListener("keydown", ["$event.target.value"])
  onInputChange(value: string) {
    // const selectionStart = this.elementRef.nativeElement.selectionStart;
    // const lastValue = (this.elementRef.nativeElement as HTMLInputElement).value;
    // const filteredValue: string = this.commas ? filterValue(value) : value;
    // console.log(lastValue, filteredValue);
    // this.updateTextInput(filteredValue, this.value !== filteredValue);
  }

  // @HostListener("keypress", ["$event"])
  // onKeyPress(event: any) {
  //   if (
  //     event.which === 45 ||
  //     event.which === 101 ||
  //     event.which === 43 ||
  //     event.which === 46
  //   ) {
  //     event.preventDefault();
  //   }
  // }

  @HostListener("blur")
  onBlur() {}

  private updateTextInput(value: string, propagateChange: boolean) {
    this.value = value;
    this.elementRef.nativeElement.value = value;
    // console.log(this.elementRef.nativeElement.value);
  }

  // ControlValueAccessor Interface

  setDisabledState(isDisabled: boolean): void {
    this.renderer.setProperty(
      this.elementRef.nativeElement,
      "disabled",
      isDisabled
    );
  }
}

function filterValue(value): string {
  return value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
