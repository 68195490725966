import {
  Directive,
  ElementRef,
  Input,
  OnDestroy,
  ViewContainerRef,
} from "@angular/core";
import { Menu } from "./menu.interface";
import { NoopScrollStrategy, Overlay, OverlayRef } from "@angular/cdk/overlay";
import { Observable, Subscription, merge } from "rxjs";
import { TemplatePortal } from "@angular/cdk/portal";

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: "[gMenuTriggerFor]",
  host: {
    "(click)": "toggleDropdown()",
  },
})
export class MenuTriggerForDirective implements OnDestroy {
  private isDropdownOpen = false;
  private overlayRef: OverlayRef;
  private dropdownClosingActionsSub = Subscription.EMPTY;

  @Input("gMenuTriggerFor") public dropdownPanel: Menu;
  @Input() gClass: string;
  @Input() originX: "start" | "end" = "start";
  @Input() originY: "top" | "bottom" = "bottom";

  constructor(
    private overlay: Overlay,
    private elementRef: ElementRef<HTMLElement>,
    private viewContainerRef: ViewContainerRef
  ) {}

  toggleDropdown(): void {
    this.isDropdownOpen ? this.destroyDropdown() : this.openDropdown();
  }

  openDropdown(): void {
    const scrollStrategy = this.overlay.scrollStrategies.reposition();

    this.isDropdownOpen = true;
    this.overlayRef = this.overlay.create({
      hasBackdrop: true,
      backdropClass: "cdk-overlay-transparent-backdrop",
      panelClass: ["g-overlay-panel", this.gClass, this.originX, this.originY],
      scrollStrategy: this.overlay.scrollStrategies.block(),
      positionStrategy: this.overlay
        .position()
        .flexibleConnectedTo(this.elementRef)
        .withPositions([
          {
            originX: this.originX,
            originY: this.originY,
            overlayX: this.originX,
            overlayY: this.originY,
          },
        ]),
    });

    const templatePortal = new TemplatePortal(
      this.dropdownPanel.templateRef,
      this.viewContainerRef
    );
    this.overlayRef.attach(templatePortal);

    this.dropdownClosingActionsSub = this.dropdownClosingActions().subscribe(
      () => this.destroyDropdown()
    );
  }

  private dropdownClosingActions(): Observable<MouseEvent | void> {
    const backdropClick$ = this.overlayRef.backdropClick();
    const detachment$ = this.overlayRef.detachments();
    const dropdownClosed = this.dropdownPanel.closed;

    return merge(backdropClick$, detachment$, dropdownClosed);
  }

  private destroyDropdown(): void {
    if (!this.overlayRef || !this.isDropdownOpen) {
      return;
    }

    this.dropdownClosingActionsSub.unsubscribe();
    this.isDropdownOpen = false;
    this.overlayRef.detach();
  }

  ngOnDestroy(): void {
    if (this.overlayRef) {
      this.overlayRef.dispose();
    }
  }
}
