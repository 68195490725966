import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "g-input-row-label",
  template: ` <div g-typography type="subHeadline">
    <ng-content></ng-content><span *ngIf="required">*</span>
  </div>`,
  styles: [
    `
      div {
        color: var(--color-grey-600);
        margin-bottom: 0;
      }
      span {
        color: var(--color-red-500);
      }
    `,
  ],
})
export class InputRowLabelComponent implements OnInit {
  @Input() required: boolean;
  constructor() {}

  ngOnInit() {}
}
