import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { Subject } from "rxjs";

export interface PaginationItemRenderContext {
  $implicit: "page" | "prev" | "next";
  page: number;
}

@Component({
  selector: "g-pagination",
  template: `<ng-template #renderItemTemplate let-type let-page="page">
  <div class="g-pagination-item-link" *ngIf="type === 'pre'">
    <mat-icon>chevron_left</mat-icon>
  </div>
  <div class="g-pagination-item-link" *ngIf="type === 'next'">
    <mat-icon>chevron_right</mat-icon>
  </div>
  <div *ngIf="type == 'page'">{{ page }}</div>
</ng-template>

<ng-container *ngIf="total > pageSize || total">
  <div class="g-pagination" type="subHeadline" g-typography>
    <div class="g-pagination-label">
      {{ pageSize * (pageIndex - 1) + 1 }} -
      {{
        pageSize * (pageIndex - 1) + pageSize > total
          ? total
          : pageSize * (pageIndex - 1) + pageSize
      }}
      / {{ total }} Kết quả
    </div>
    <ul class="g-pagination-list" [class.g-pagination-disabled]="disabled">
      <li
        class="g-pagination-item g-pagination-prev"
        [attr.title]="'prev-page'"
        [class.g-pagination-disabled]="isFirstIndex"
        (click)="jumpDiff(-1)"
      >
        <ng-template
          [ngTemplateOutlet]="itemRender"
          [ngTemplateOutletContext]="{ $implicit: 'pre' }"
        ></ng-template>
      </li>
      <li
        class="g-pagination-item"
        [attr.title]="firstIndex"
        [class.g-pagination-item-active]="isFirstIndex"
        (click)="jumpPage(firstIndex)"
      >
        <ng-template
          [ngTemplateOutlet]="itemRender"
          [ngTemplateOutletContext]="{ $implicit: 'page', page: firstIndex }"
        ></ng-template>
      </li>
      <li
        class="g-pagination-jump-prev g-pagination-item-link-icon g-pagination-item"
        *ngIf="lastIndex > 9 && pageIndex - 3 > firstIndex"
        [attr.title]="'prev'"
        (click)="jumpDiff(-5)"
      >
        <div class="g-pagination-item-link">
          <div class="g-pagination-item-container">
            <span class="g-pagination-item-ellipsis">•••</span>
          </div>
        </div>
      </li>
      <li
        class="g-pagination-item"
        *ngFor="let page of pages"
        [attr.title]="page"
        [class.g-pagination-item-active]="pageIndex === page"
        (click)="jumpPage(page)"
      >
        <ng-template
          [ngTemplateOutlet]="itemRender"
          [ngTemplateOutletContext]="{ $implicit: 'page', page: page }"
        ></ng-template>
      </li>
      <li
        class="g-pagination-jump-next g-pagination-item-link-icon g-pagination-item"
        [attr.title]="'next'"
        (click)="jumpDiff(5)"
        *ngIf="lastIndex > 9 && pageIndex + 3 < lastIndex"
      >
        <div class="g-pagination-item-link">
          <div class="g-pagination-item-container">
            <span class="g-pagination-item-ellipsis">•••</span>
          </div>
        </div>
      </li>
      <li
        class="g-pagination-item"
        [attr.title]="lastIndex"
        (click)="jumpPage(lastIndex)"
        *ngIf="lastIndex > 0 && lastIndex !== firstIndex"
        [class.g-pagination-item-active]="isLastIndex"
      >
        <ng-template
          [ngTemplateOutlet]="itemRender"
          [ngTemplateOutletContext]="{ $implicit: 'page', page: lastIndex }"
        ></ng-template>
      </li>
      <li
        class="g-pagination-item g-pagination-next"
        [title]="'next-page'"
        [class.g-pagination-disabled]="isLastIndex"
        (click)="jumpDiff(1)"
      >
        <ng-template
          [ngTemplateOutlet]="itemRender"
          [ngTemplateOutletContext]="{ $implicit: 'next' }"
        ></ng-template>
      </li>
    </ul></div
></ng-container>
`,
  styles: [`.g-pagination{display:flex;align-items:center;justify-content:space-between}.g-pagination-label{margin-right:20px;color:var(--color-grey-600)}.g-pagination-list{list-style:none;padding-left:0;display:flex;margin:0 -5px}.g-pagination-item{width:32px;height:32px;border:1px solid var(--color-grey-200);border-radius:50%;display:flex;align-items:center;justify-content:center;margin:0 5px;cursor:pointer}.g-pagination-item div{display:inline-flex}.g-pagination-item-active{border-color:var(--color-primary)}.g-pagination-item-link-icon{border:none}`],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaginationComponent implements OnInit, OnChanges {
  firstIndex = 1;
  pages: number[] = [];
  private $destroy = new Subject<void>();
  @Input() total = 0;
  @Input() pageIndex = 1;
  @Input() pageSize = 10;
  @Input() disabled: boolean;
  @Input() showLabel: boolean;
  @Output() readonly pageSizeChange: EventEmitter<number> = new EventEmitter();
  @Output() readonly pageIndexChange: EventEmitter<number> = new EventEmitter();
  @ViewChild("renderItemTemplate", { static: true })
  nzItemRenderChild: TemplateRef<PaginationItemRenderContext>;
  get itemRender(): TemplateRef<PaginationItemRenderContext> {
    return this.nzItemRenderChild;
  }
  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit() {}

  validatePageIndex(value: number): number {
    if (value > this.lastIndex) {
      return this.lastIndex;
    } else if (value < this.firstIndex) {
      return this.firstIndex;
    } else {
      return value;
    }
  }

  updatePageIndexValue(page: number): void {
    this.pageIndex = page;
    this.pageIndexChange.emit(this.pageIndex);
    this.buildIndexes();
  }

  isPageIndexValid(value: number): boolean {
    return this.validatePageIndex(value) === value;
  }

  jumpPage(index: number): void {
    if (index !== this.pageIndex && !this.disabled) {
      const pageIndex = this.validatePageIndex(index);
      if (pageIndex !== this.pageIndex) {
        this.updatePageIndexValue(pageIndex);
      }
    }
  }

  jumpDiff(diff: number): void {
    this.jumpPage(this.pageIndex + diff);
  }

  onPageSizeChange($event: number): void {
    this.pageSize = $event;
    this.pageSizeChange.emit($event);
    this.buildIndexes();
    if (this.pageIndex > this.lastIndex) {
      this.updatePageIndexValue(this.lastIndex);
    }
  }

  buildIndexes(): void {
    const pages: number[] = [];
    if (this.lastIndex <= 9) {
      for (let i = 2; i <= this.lastIndex - 1; i++) {
        pages.push(i);
      }
    } else {
      const current = +this.pageIndex;
      let left = Math.max(2, current - 2);
      let right = Math.min(current + 2, this.lastIndex - 1);
      if (current - 1 <= 2) {
        right = 5;
      }
      if (this.lastIndex - current <= 2) {
        left = this.lastIndex - 4;
      }
      for (let i = left; i <= right; i++) {
        pages.push(i);
      }
    }
    this.pages = pages;
    this.cdr.markForCheck();
  }

  get lastIndex(): number {
    return Math.ceil(this.total / this.pageSize);
  }

  get isLastIndex(): boolean {
    return this.pageIndex === this.lastIndex;
  }

  get isFirstIndex(): boolean {
    return this.pageIndex === this.firstIndex;
  }

  get ranges(): number[] {
    return [
      (this.pageIndex - 1) * this.pageSize + 1,
      Math.min(this.pageIndex * this.pageSize, this.total),
    ];
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.total || changes.pageSize || changes.pageIndex) {
      this.buildIndexes();
    }
  }
}
