import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { InputGroupComponent } from "./input-group.component";
import { InputRowComponent } from "./input-row.component";
import { InputRowLabelComponent } from "./input-row-label.component";
import { InputRowItemComponent } from "./input-row-item.component";
import { GTypographyModule } from "../typography/typography.module";

@NgModule({
  declarations: [
    InputGroupComponent,
    InputRowComponent,
    InputRowLabelComponent,
    InputRowItemComponent,
  ],
  exports: [
    InputGroupComponent,
    InputRowComponent,
    InputRowLabelComponent,
    InputRowItemComponent,
  ],
  imports: [CommonModule, GTypographyModule],
})
export class GInputGroupModule {}
