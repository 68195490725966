import {
  AfterContentInit,
  AfterViewInit,
  ContentChild,
  Directive,
  ElementRef,
  HostBinding,
  Input,
} from "@angular/core";
import { PlaceholderDirective } from "./placeholder.directive";
import { UpdateHostClassService } from "../services/update-host-class.service";

@Directive({
  selector: "[g-placeholder-animation]",
})
export class PlaceholderAnimationDirective
  implements AfterContentInit, AfterViewInit
{
  constructor(
    private _updateHostClassService: UpdateHostClassService,
    private elementRef: ElementRef
  ) {}
  @Input("g-placeholder-animation") animation?: "glow" | "wave";
  @ContentChild(PlaceholderDirective, { static: true })
  placeholder!: PlaceholderDirective;
  private animate = false;

  ngAfterContentInit() {
    this.animate = this.placeholder.visible;
  }

  ngAfterViewInit(): void {
    this._updateHostClassService.updateHostClass(
      this.elementRef.nativeElement,
      {
        [`g-placeholder-${this.animation}`]: this.animate && !!this.animation,
      }
    );
  }
}
