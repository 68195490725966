import {
  AfterViewInit,
  Directive,
  ElementRef,
  HostBinding,
  Input,
} from "@angular/core";
import { coerceBooleanProperty } from "@angular/cdk/coercion";
import { UpdateHostClassService } from "../services/update-host-class.service";

@Directive({
  selector: "[g-placeholder]",
})
export class PlaceholderDirective implements AfterViewInit {
  constructor(
    private _updateHostClassService: UpdateHostClassService,
    private elementRef: ElementRef
  ) {}
  @Input("g-placeholder")
  set visible(value: boolean) {
    this._visible = coerceBooleanProperty(value);
  }
  get visible() {
    return this._visible;
  }
  private _visible = false;

  // tslint:disable-next-line:no-input-rename
  @Input("size") size?: "small" | "standard" = "small";

  @HostBinding("attr.aria-hidden")
  get ariaHidden(): boolean | null {
    return this.visible ? null : true;
  }

  ngAfterViewInit(): void {
    this._updateHostClassService.updateHostClass(
      this.elementRef.nativeElement,
      {
        "g-placeholder": this.visible,
        [`g-placeholder-${this.size}`]: !!this.size,
      }
    );
  }
}
