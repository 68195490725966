import { Directive } from "@angular/core";

@Directive({
  selector: "[g-button-icon]",
  host: {
    class: "g-button-icon",
  },
})
export class ButtonIconDirective {
  constructor() {}
}
