import { Directive, ElementRef, Input } from "@angular/core";

@Directive({
  selector: "[g-tab-label]",
  host: {
    "[class.g-tab-disabled]": "disabled",
    class: "g-tab-label g-tab-item",
    role: "tab",
  },
})
export class TabLabelDirective {
  @Input() disabled: boolean;
  constructor(public elementRef: ElementRef) {}
}
