import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { PaginationComponent } from "./pagination.component";
import { MatIconModule } from "@angular/material";
import { GTypographyModule } from "../typography/typography.module";

@NgModule({
  declarations: [PaginationComponent],
  imports: [CommonModule, MatIconModule, GTypographyModule],
  exports: [PaginationComponent],
})
export class GPaginationModule {}
