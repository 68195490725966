import {
  AfterContentInit,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  Renderer2,
  SimpleChanges,
  ViewEncapsulation,
} from "@angular/core";

@Component({
  selector: "g-avatar",
  template: `<img *ngIf="src && hasSrc" [src]="src" (error)="imgError($event)" />
<ng-content select="g-avatar-prefix"></ng-content>
`,
  styles: [`.g-avatar{display:inline-flex;border-radius:50%;border:2px solid var(--color-grey-200);position:relative;overflow:hidden}.g-avatar>img{width:100%;height:100%;position:absolute;top:0;left:0;-o-object-fit:cover;object-fit:cover}.g-avatar g-avatar-prefix{max-width:22px;position:absolute;top:50%;left:50%;-webkit-transform:translate(-50%,-50%);transform:translate(-50%,-50%);z-index:2;display:inline-flex}.g-avatar g-avatar-prefix img{max-width:100%}.g-avatar g-avatar-prefix *{margin-bottom:0}`],
  host: {
    class: "g-avatar",
  },
  encapsulation: ViewEncapsulation.None,
})
export class AvatarComponent implements OnInit, OnChanges {
  @Input() src: string;
  @Input() size = 64;

  hasSrc = true;

  private el: HTMLElement = this.elementRef.nativeElement;

  constructor(public elementRef: ElementRef, private renderer: Renderer2) {}

  ngOnInit() {}

  imgError($event: Event): void {
    // this.nzError.emit($event);
    if (!$event.defaultPrevented) {
      this.hasSrc = false;
      // if (this.icon) {
      //   this.hasIcon = true;
      // } else if (this.nzText) {
      //   this.hasText = true;
      // }
      this.setSizeStyle();
    }
  }

  private setSizeStyle(): void {
    const size = typeof this.size === "string" ? this.size : `${this.size}px`;
    this.renderer.setStyle(this.el, "width", size);
    this.renderer.setStyle(this.el, "height", size);
    this.renderer.setStyle(this.el, "line-height", size);
    // if (this.hasIcon) {
    //   this.renderer.setStyle(this.el, "font-size", `calc(${size} / 2)`);
    // }
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { size } = changes;
    if (size) {
    }
    this.setSizeStyle();
  }

  // ngAfterContentInit(): void {
  //     this.setSizeStyle()
  // }
}
