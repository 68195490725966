import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DatetimePickerComponent } from "./datetime-picker.component";
import { GTypographyModule } from "../typography/typography.module";
import { GClickOutsideModule } from "../directives/click-outside/click-outside.module";
import {
  MatDatepickerModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
} from "@angular/material";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import {
  NgxMatDatetimePickerModule,
  NgxMatNativeDateModule,
  NgxMatTimepickerModule,
} from "@angular-material-components/datetime-picker";

@NgModule({
  declarations: [DatetimePickerComponent],
  imports: [
    CommonModule,
    GTypographyModule,
    GClickOutsideModule,
    MatIconModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMatDatetimePickerModule,
    NgxMatNativeDateModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
  ],
  exports: [DatetimePickerComponent],
})
export class GDatetimePickerModule {}
