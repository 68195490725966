import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { GStringTemplateOutletDirective } from "./string-template-outlet";

@NgModule({
  imports: [CommonModule],
  exports: [GStringTemplateOutletDirective],
  declarations: [GStringTemplateOutletDirective],
})
export class GAddOnModule {}
