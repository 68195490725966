import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BoxComponent } from "./box.component";
import { BoxContentComponent } from "./box-content.component";

@NgModule({
  declarations: [BoxComponent, BoxContentComponent],
  imports: [CommonModule],
  exports: [BoxComponent, BoxContentComponent],
})
export class GBoxModule {}
