import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AvatarComponent } from "./avatar.component";
import { AvatarPrefixDirective } from "./avatar-prefix.directive";

@NgModule({
  declarations: [AvatarComponent, AvatarPrefixDirective],
  imports: [CommonModule],
  exports: [AvatarComponent, AvatarPrefixDirective],
})
export class GAvatarModule {}
