import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
} from "@angular/core";
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { SelectableValueDirective } from "../select/selectable-value.directive";

@Component({
  selector: "g-phone-number",
  template: `<div
  class="g-phone-number"
  *ngIf="selectOptionList && selectOptionList.length"
  [formGroup]="phoneForm"
>
  <div class="g-phone-number-code">
    <g-select
      [appearance]="appearance"
      [rounded]="true"
      [placeholder]="placeholder"
      [readonly]="readonly"
      [disabled]="disabled"
      [control]="phoneForm.get('phoneCode')"
    >
      <ng-container *ngFor="let item of selectOptionList">
        <g-option *optionValue="item.value">
          <ng-template [ngTemplateOutlet]="item.templateRef"></ng-template>
        </g-option>
      </ng-container>
    </g-select>
  </div>
  <div class="g-phone-number-value">
    <g-input
      [appearance]="appearance"
      [rounded]="true"
      [placeholder]="placeholder"
      [readonly]="readonly"
      [isNumber]="true"
      [disabled]="disabled"
      [required]="required"
      [control]="phoneForm.get('phoneNumber')"
      (focusChange)="focusChange($event)"
    ></g-input>
  </div>
</div>
`,
  styles: [`.g-phone-number{display:flex;margin:0 -6px}.g-phone-number>*{padding:0 6px}.g-phone-number-code{min-width:130px}.g-phone-number-value{flex:1}`],
})
export class PhoneNumberComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() required: boolean;
  @Input() appearance: "fill" | "outline" = "fill";
  @Input() rounded = true;
  @Input() placeholder: string;
  @Input() disabled: boolean;
  @Input() readonly: boolean;
  @Input() clearButton: boolean;
  @Input() submited: boolean;
  @Input() control: AbstractControl = new FormControl(null);
  @ContentChildren(SelectableValueDirective, { descendants: true })
  selectOptionList: QueryList<SelectableValueDirective>;
  // tslint:disable-next-line:no-output-on-prefix
  @Output() onChange = new EventEmitter<any>();

  phoneForm: FormGroup;

  constructor(
    private _formBuilder: FormBuilder,
    private _cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this._createForm();
    if (this.phoneForm) {
      this.phoneForm.valueChanges.pipe().subscribe((value) => {
        this.control.setValue([value.phoneCode, value.phoneNumber]);
        this.onChange.emit([value.phoneCode, value.phoneNumber]);
        this.validatePhoneNumber();

        this._cdr.detectChanges();
      });
    }
  }

  private _createForm() {
    this.phoneForm = this._formBuilder.group({
      phoneCode: new FormControl(
        null,
        this.required ? Validators.required : null
      ),
      phoneNumber: new FormControl(
        null,
        this.required
          ? [
              Validators.required,
              Validators.pattern("^[0-9]*$"),
              Validators.minLength(9),
            ]
          : [Validators.pattern("^[0-9]*$"), , Validators.minLength(9)]
      ),
    });
  }

  focusChange(event) {
    console.log(event);
    if (!event) {
      this.control.markAsTouched();
    }
  }

  validatePhoneNumber() {
    if (this.phoneForm.get("phoneNumber").errors) {
      this.control.setErrors(this.phoneForm.get("phoneNumber").errors);
    } else {
      this.control.setErrors(null);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { submited } = changes;
    if (submited && submited.currentValue) {
      this.markFormTouched(this.phoneForm);
      this.validatePhoneNumber();
    }
  }
  markFormTouched(group: FormGroup) {
    Object.keys(group.controls).forEach((key: string) => {
      const control = group.controls[key];
      if (control instanceof FormGroup) {
        control.markAsTouched();

        this.markFormTouched(control);
      } else {
        control.markAsTouched();
      }
    });
  }

  ngAfterViewInit(): void {
    if (this.control.value) {
      this.phoneForm.setValue({
        phoneCode: this.control.value[0],
        phoneNumber: this.control.value[1],
      });
    } else {
      this.phoneForm.get("phoneCode").setValue(84);
    }
  }
}
