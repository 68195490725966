import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BreadcrumbComponent } from "./breadcrumb.component";
import { BreadcrumbItemComponent } from "./breadcrumb-item.component";
import { MatIconModule } from "@angular/material";
import { GAddOnModule } from "../core/public-api";

@NgModule({
  declarations: [BreadcrumbComponent, BreadcrumbItemComponent],
  imports: [CommonModule, MatIconModule, GAddOnModule],
  exports: [BreadcrumbComponent, BreadcrumbItemComponent],
})
export class GBreadcrumbModule {}
