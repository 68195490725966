import { Directive, Input, TemplateRef } from "@angular/core";

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: "[optionValue]",
})
export class SelectableValueDirective {
  // tslint:disable-next-line:no-input-rename
  @Input("optionValue") value: any;
  constructor(public templateRef: TemplateRef<any>) {}
}
