import {
  AfterViewInit,
  Component,
  ContentChildren,
  Input,
  OnInit,
  QueryList,
  ViewChild,
} from "@angular/core";
import { TabLabelDirective } from "./tab-label.directive";
import { TabBarDirective } from "./tab-bar.directive";

@Component({
  selector: "g-tab-header",
  template: `
    <div class="g-tab-label-container">
      <div class="g-tab-list" role="tablist">
        <ng-content></ng-content>
      </div>
      <div g-tab-bar style="display: block;"></div>
    </div>
  `,
  styles: [],
})
export class TabHeaderComponent implements OnInit, AfterViewInit {
  private _selectedIndex = 0;

  @ContentChildren(TabLabelDirective)
  listOfTabLabelDirective: QueryList<TabLabelDirective>;

  @ViewChild(TabBarDirective, { static: true })
  tabInkBarDirective: TabBarDirective;

  @Input()
  set selectedIndex(value: number) {
    this._selectedIndex = value;
    this.alignInkBarToSelectedTab();
  }

  get selectedIndex(): number {
    return this._selectedIndex;
  }
  constructor() {}

  ngOnInit() {}

  alignInkBarToSelectedTab(): void {
    const selectedLabelWrapper =
      this.listOfTabLabelDirective && this.listOfTabLabelDirective.length
        ? this.listOfTabLabelDirective.toArray()[this.selectedIndex].elementRef
            .nativeElement
        : null;
    if (this.tabInkBarDirective) {
      this.tabInkBarDirective.alignToElement(selectedLabelWrapper);
    }
  }

  ngAfterViewInit(): void {
    if (this.listOfTabLabelDirective) {
      setTimeout(() => {
        this.alignInkBarToSelectedTab();
      }, 100);
    }
  }
}
