import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { AbstractControl, ControlContainer, FormControl } from "@angular/forms";

@Component({
  selector: "g-upload",
  template: `<input
  type="file"
  accept="image/*"
  #file
  hidden
  (change)="inputChange($event.target.files)"
/>
<g-upload-content (click)="!disabled && file.click()">
  <ng-content></ng-content>
</g-upload-content>
`,
  styles: [`g-upload-content{cursor:pointer}`],
})
export class UploadComponent implements OnInit {
  private _control: AbstractControl = new FormControl(null);
  imageBase64: any;
  filename: string;
  @Input() set control(value: FormControl) {
    this._control = value;
  }
  // @Input() set controlName(name: string) {
  //   this._control = this.container.control.get(name) as FormControl;
  // }

  // tslint:disable-next-line:no-output-on-prefix
  @Output() onChange = new EventEmitter(null);
  @Input() image: string;
  @Input() disabled: boolean;
  @Output() imageChange = new EventEmitter(null);
  constructor() {}

  ngOnInit() {}

  inputChange(fileList: FileList) {
    try {
      const file = fileList[0];
      this.onChange.emit(file);
      this._control.setValue(file);
      const fReader = new FileReader();
      this.filename = file.name;
      fReader.readAsDataURL(file);
      fReader.onloadend = (_event: any) => {
        this.imageBase64 = _event.target.result;
        this.imageChange.emit(this.imageBase64);
      };
    } catch (error) {
      console.log(error);
      this.filename = "";
    }
  }
}

@Component({
  selector: "g-upload-content",
  template: `<ng-content></ng-content>`,
  styles: [``],
})
export class UploadContentComponent {}
