import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import {
  CardAvatarDirective,
  CardComponent,
  CardIconDirective,
  CardSubTitleDirective,
  CardTitleDirective,
} from "./card.component";
import { CardHeaderComponent } from "./card-header.component";
import { GTypographyModule } from "../typography/typography.module";

@NgModule({
  declarations: [
    CardComponent,
    CardHeaderComponent,
    CardTitleDirective,
    CardSubTitleDirective,
    CardIconDirective,
    CardAvatarDirective,
  ],
  imports: [CommonModule, GTypographyModule],
  exports: [
    CardComponent,
    CardHeaderComponent,
    CardTitleDirective,
    CardSubTitleDirective,
    CardIconDirective,
    CardAvatarDirective,
  ],
})
export class GCardModule {}
