import { Component, OnInit } from "@angular/core";
import { BreadcrumbComponent } from "./breadcrumb.component";

@Component({
  selector: "g-breadcrumb-item",
  template: `
    <span class="g-breadcrumb-item"
      ><ng-content></ng-content
      ><span class="g-breadcrumb-separator">
        <ng-container *ngIf="!breadcrumbComponent.gSeparator; else separator">
          <mat-icon>chevron_right</mat-icon>
        </ng-container>
      </span>
      <ng-template #separator>
        <ng-container *gStringTemplateOutlet="breadcrumbComponent.gSeparator">
          {{ breadcrumbComponent.gSeparator }}
        </ng-container>
      </ng-template>
    </span>
  `,
  styles: [
    `
      .g-breadcrumb-item {
        display: inline-flex;
        align-items: center;
      }
      .g-breadcrumb-separator {
        margin-left: 8px;
        display: inline-flex;
      }
    `,
  ],
})
export class BreadcrumbItemComponent implements OnInit {
  constructor(public breadcrumbComponent: BreadcrumbComponent) {}

  ngOnInit() {}
}
