import {
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewEncapsulation,
} from "@angular/core";

@Component({
  // tslint:disable-next-line:component-selector
  selector: "[g-menu-item]",
  template: `<ng-content select=".icon, mat-icon, img, g-icon"></ng-content>
<span class="g-list-item__primary-text"><ng-content></ng-content></span>
<div
  class="g-menu-ripple"
  matRipple
  [matRippleDisabled]="disableRipple || disabled"
  [matRippleTrigger]="_getHostElement()"
></div>
`,
  styles: [`.g-menu-item{justify-content:space-between}.g-menu-item .icon,.g-menu-item g-icon,.g-menu-item img,.g-menu-item mat-icon{order:2;margin-left:5px}`],
  host: {
    // "[attr.role]": "role",
    class: "g-menu-item g-focus-indicator mdc-list-item",
    // "[class.g-menu-item-highlighted]": "_highlighted",
    // "[class.g-menu-item-submenu-trigger]": "_triggersSubmenu",
    "[attr.tabindex]": "_getTabIndex()",
    "[attr.aria-disabled]": "disabled",
    "[attr.disabled]": "disabled || null",
    // "(click)": "_checkDisabled($event)",
    // "(mouseenter)": "_handleMouseEnter()",
  },
  encapsulation: ViewEncapsulation.None,
})
export class MenuItemComponent implements OnInit {
  @Input() disabled: boolean;
  @Input() disableRipple: boolean;

  constructor(private _elementRef: ElementRef) {}

  ngOnInit() {}

  _getHostElement(): HTMLElement {
    return this._elementRef.nativeElement;
  }

  _getTabIndex(): string {
    return this.disabled ? "-1" : "0";
  }
}
