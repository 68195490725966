import {
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewEncapsulation,
} from "@angular/core";
import { TabItemComponent } from "./tab-item.component";

@Component({
  selector: "g-tab",
  template: `<ng-container *ngIf="listOfTabItemComponent">
  <g-tab-header [selectedIndex]="selectedIndex">
    <div
      g-tab-label
      g-typography
      [type]="labelType"
      role="tab"
      [class.g-tab-label-active]="i == selectedIndex"
      [class.g-tab-label-disabled]="tab.disabled"
      (click)="clickLabel(i, tab.disabled)"
      tabindex="-1"
      [style.margin-right.px]="gutter"
      *ngFor="let tab of listOfTabItemComponent; let i = index"
    >
      <ng-container *gStringTemplateOutlet="tab.label || tab.title">
        {{ tab.label }}
      </ng-container>
    </div>
  </g-tab-header>
  <div class="g-tab-content">
    <div
      g-tab-body
      *ngFor="let tab of listOfTabItemComponent; let i = index"
      [content]="tab.content"
      [active]="i == selectedIndex"
    ></div>
  </div>
</ng-container>
`,
  styles: [`.g-tab-list{display:flex}.g-tab-label{padding:16px 0;cursor:pointer;text-align:center;min-width:90px;margin-bottom:0!important;font-weight:600;color:var(--color-grey-600)}.g-tab-label a{color:var(--color-grey-600)}.g-tab-label-active,.g-tab-label-active a{color:var(--color-grey-900)!important}.g-tab-label-link{padding:16px;margin:-16px 0;display:block;width:100%}.g-tab-label-container{position:relative}.g-tab-ink-bar{position:absolute;bottom:0;height:2px;background:var(--color-primary);transition:.3s}`],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: "g-tab",
  },
})
export class TabComponent implements OnInit {
  @ContentChildren(TabItemComponent)
  listOfTabItemComponent: QueryList<TabItemComponent>;
  @Input() selectedIndex = 0;
  @Input() labelType: string;
  @Input() gutter = 0;
  @Output() selectedIndexChange: EventEmitter<number> = new EventEmitter();
  constructor() {}

  ngOnInit() {}

  clickLabel(index: number, disabled: boolean) {
    if (!disabled) {
      this.selectedIndex = index;
      this.selectedIndexChange.emit(index);
    }
  }
}
