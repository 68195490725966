import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { UploadComponent, UploadContentComponent } from "./upload.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

@NgModule({
  declarations: [UploadComponent, UploadContentComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule],
  exports: [UploadComponent, UploadContentComponent],
})
export class GUploadModule {}
