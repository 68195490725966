import { Directive } from "@angular/core";

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: "g-prefix",
  host: {
    style: "display: flex",
  },
})
export class InputPrefixDirective {
  constructor() {}
}
