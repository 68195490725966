import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ButtonComponent } from "./button.component";
import { UpdateHostClassService } from "../services/update-host-class.service";
import { MatRippleModule } from "@angular/material";
import { ButtonIconDirective } from "./button-icon.directive";

@NgModule({
  declarations: [ButtonComponent, ButtonIconDirective],
  imports: [CommonModule, MatRippleModule],
  providers: [UpdateHostClassService],
  exports: [ButtonComponent, ButtonIconDirective],
})
export class GButtonModule {}
