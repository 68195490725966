import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewEncapsulation,
} from "@angular/core";
import { TableColumn } from "./table.interface";
import { MatTableDataSource } from "@angular/material";

@Component({
  selector: "g-table",
  template: `<div class="g-table" *ngIf="data">
  <table>
    <ng-content></ng-content>
  </table>
</div>
`,
  styles: [`.g-table{border-radius:12px!important;border:1px solid var(--color-grey-200);display:flex;overflow-x:auto}.g-table table{width:100%;box-shadow:none!important}.g-table table tr{min-height:48px;height:auto!important}.g-table table td,.g-table table th,.g-table table tr{border:none}.g-table table thead th{color:var(--color-grey-600);font-weight:400}.g-table table th{white-space:nowrap}.g-table table tbody tr td{border-top:1px solid var(--color-grey-200)}.g-table table td,.g-table table th{padding:16px}.g-table .likeLink{cursor:pointer}.g-table .likeLink:hover{color:#0052b4;text-decoration:underline}.g-table .text-center{text-align:center!important}`],
  encapsulation: ViewEncapsulation.None,
})
export class TableComponent implements OnInit, OnChanges {
  // tslint:disable-next-line:no-input-rename
  @Input("tableData") data: any;
  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {}
}
