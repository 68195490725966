import { Component, OnInit } from "@angular/core";

@Component({
  selector: "g-card-header",
  template: `<div g-typography class="g-card-title">
      <ng-content select="g-card-title"></ng-content>
    </div>
    <div g-typography type="small" class="g-card-subtitle">
      <ng-content select="g-card-subtitle"></ng-content>
    </div>`,
  styles: [
    `
      div {
        margin-bottom: 0;
      }
      .g-card-subtitle {
        color: var(--color-grey-600);
        margin-bottom: 0;
      }
    `,
  ],
})
export class CardHeaderComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
