import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { PlaceholderAnimationDirective } from "./placeholder-animation.directive";
import { PlaceholderDirective } from "./placeholder.directive";

@NgModule({
  declarations: [PlaceholderAnimationDirective, PlaceholderDirective],
  imports: [CommonModule],
  exports: [PlaceholderAnimationDirective, PlaceholderDirective],
})
export class GPlaceholderModule {}
