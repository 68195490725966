import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Output,
} from "@angular/core";

@Directive({
  selector: "[g-outside]",
})
export class ClickOutsideDirective {
  @Output()
  clickOutside: EventEmitter<MouseEvent> = new EventEmitter();

  @HostListener("document:mousedown", ["$event"])
  onClick(event: MouseEvent): void {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.clickOutside.emit(event);
    }
  }

  constructor(private elementRef: ElementRef) {}
}
