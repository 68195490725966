import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  Renderer2,
  forwardRef,
} from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: "[gNumber]",
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NumberDirective),
      multi: true,
    },
  ],
})
export class NumberDirective {
  private onChange: (val: string) => void;
  private onTouched: () => void;
  private value: string;

  constructor(private elementRef: ElementRef, private renderer: Renderer2) {}
  @Input("gNumber") isNumber: boolean;
  @Input() hasCommas = false;
  @Input() isTaxCode = false;

  @HostListener("input", ["$event.target.value"])
  onInputChange(value: string) {
    const selectionStart = this.elementRef.nativeElement.selectionStart;
    const selectionEnd = this.elementRef.nativeElement.selectionEnd;
    const filteredValue: string = this.isNumber
      ? filterValue(value, this.hasCommas, this.isTaxCode)
      : value;
    this.updateTextInput(filteredValue, this.value !== filteredValue);

    const formattedToLeftOfCursor = filteredValue.substring(0, selectionStart);
    const commasFormatted = formattedToLeftOfCursor.split(",").length - 1;

    const valToLeftOfCursor = value.substring(0, selectionStart);
    const commasInVal = valToLeftOfCursor.split(",").length - 1;
    if (selectionStart === selectionEnd) {
      const setPos = selectionStart + commasFormatted - commasInVal;
      this.elementRef.nativeElement.setSelectionRange(setPos, setPos);
    }
  }

  @HostListener("blur")
  onBlur() {
    this.onTouched();
  }

  private updateTextInput(value: string, propagateChange: boolean) {
    this.renderer.setProperty(this.elementRef.nativeElement, "value", value);
    this.value = value;
    if (this.isNumber) {
      this.value = value.split(",").join("");
    }
    if (propagateChange) {
      this.onChange(this.value);
    }
  }

  // ControlValueAccessor Interface
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.renderer.setProperty(
      this.elementRef.nativeElement,
      "disabled",
      isDisabled
    );
  }

  writeValue(value: any): void {
    value = value ? value : "";

    if (this.isNumber) {
      value = value
        ? filterValue("" + value, this.hasCommas, this.isTaxCode)
        : "";
    }
    this.updateTextInput(value, false);
  }
}

function filterValue(value, hasCommas = false, isTaxCode = false): string {
  let newValue: string;
  if (isTaxCode) {
    newValue = value.replace(/[^0-9-]*/g, "");
  } else {
    newValue = value.replace(/[^0-9]*/g, "");
  }
  if (hasCommas) {
    newValue = newValue.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  return newValue;
}
