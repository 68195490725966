import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  ViewEncapsulation,
} from "@angular/core";

@Component({
  selector: "g-input-row-item",
  template: ` <ng-content></ng-content>`,
  styles: [`.g-menu-item{display:flex;position:relative;align-items:center;justify-content:flex-start;overflow:hidden;padding:0 16px;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;outline:0;border:none;-webkit-tap-highlight-color:transparent;cursor:pointer;width:100%;text-align:left;box-sizing:border-box;color:inherit;font-size:inherit;background:0 0;text-decoration:none;margin:0;min-height:48px}.g-menu-item:hover:not([disabled]){background:rgba(0,0,0,.04)}.g-menu-item[disabled]{cursor:default;opacity:.38}.g-placeholder{display:inline-block;min-height:1em;vertical-align:middle;cursor:wait;width:100%;background-color:var(--color-grey-500);opacity:.5}.g-placeholder.btn::before{display:inline-block;content:""}.g-placeholder-small{min-height:16px}.g-placeholder-standard{min-height:24px}.g-placeholder-glow .g-placeholder{-webkit-animation:2s ease-in-out infinite placeholder-glow;animation:2s ease-in-out infinite placeholder-glow}@-webkit-keyframes placeholder-glow{50%{opacity:.2}}@keyframes placeholder-glow{50%{opacity:.2}}.g-placeholder-wave{-webkit-mask-image:linear-gradient(130deg,#000 25%,rgba(0,0,0,.8) 75%,#000 55%);mask-image:linear-gradient(130deg,#000 25%,rgba(0,0,0,.8) 75%,#000 55%);-webkit-mask-size:200% 100%;mask-size:200% 100%;-webkit-animation:2s linear infinite placeholder-wave;animation:2s linear infinite placeholder-wave}@-webkit-keyframes placeholder-wave{100%{-webkit-mask-position:-200% 0;mask-position:-200% 0}}@keyframes placeholder-wave{100%{-webkit-mask-position:-200% 0;mask-position:-200% 0}}g-input-row-item{display:flex;margin:0 -16px}g-input-row-item>*{padding:0 16px;flex:1}`],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputRowItemComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
