import { Directive, Input } from "@angular/core";

@Directive({
  selector: `[g-ellipsis]`,
  host: {
    class: "g-ellipsis",
    style: `display: -webkit-box; overflow: hidden; text-overflow: ellipsis;-webkit-box-orient: vertical`,
    "[style.-webkit-line-clamp]": "ellipsisRow",
  },
})
export class EllipsisDirective {
  @Input() ellipsisRow = 3;
  constructor() {}
}
