import {
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from "@angular/core";

@Component({
  selector: "g-phone-number-code",
  template: `<ng-template #content><ng-content></ng-content></ng-template>`,
  styles: [],
})
export class PhoneNumberCodeComponent implements OnInit {
  @Input() value;
  @ViewChild("content", { static: true }) content: TemplateRef<any>;
  constructor() {}

  ngOnInit() {}
}
