import { Directive, Input, TemplateRef } from "@angular/core";

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: "[gTemplate]",
  host: {},
})
// tslint:disable-next-line:directive-class-suffix
export class GTemplateDirective {
  @Input() type: string | undefined;

  // tslint:disable-next-line:no-input-rename
  @Input("gTemplate") name: string | undefined;

  constructor(public template: TemplateRef<any>) {}

  getType(): string {
    // tslint:disable-next-line:no-non-null-assertion
    return this.name;
  }
}
