import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { InputComponent } from "./input.component";
import { GTypographyModule } from "../typography/typography.module";
import { GClickOutsideModule } from "../directives/click-outside/click-outside.module";
import { InputPrefixDirective } from "./input-prefix.directive";
import {
  MatDatepickerModule,
  MatIconModule,
  MatNativeDateModule,
} from "@angular/material";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { GNumberModule } from "../directives/number/number.module";
import { GCommasModule } from "../directives/commas/commas.module";

@NgModule({
  declarations: [InputComponent, InputPrefixDirective],
  exports: [InputComponent, InputPrefixDirective],
  imports: [
    CommonModule,
    GTypographyModule,
    GClickOutsideModule,
    MatIconModule,
    FormsModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    GNumberModule,
    GCommasModule,
  ],
})
export class GInputModule {}
