import { Injectable } from "@angular/core";
import { completeIconSet, GtdFlag } from "@gotadi/flags";

@Injectable({
  providedIn: "root",
})
export class IconService {
  private registry = new Map<string, string>();

  public registerIcons(): void {
    completeIconSet.forEach((icon: GtdFlag) =>
      this.registry.set(icon.name, icon.data)
    );
  }

  public getIcon(iconName: string): string | undefined {
    if (!this.registry.has(iconName)) {
      console.warn(
        `We could not find the gotadi Icon with the name ${iconName}, did you add it to the Icon registry?`
      );
    }
    return this.registry.get(iconName);
  }
}
