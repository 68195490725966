import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MultiSelectComponent } from "./multiselect.component";
import { GTypographyModule } from "../typography/typography.module";
import { GClickOutsideModule } from "../directives/click-outside/click-outside.module";
import { MatIconModule } from "@angular/material";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { GSelectModule } from "../select/select.module";
import { GTemplateModule } from "../directives/template/template.module";
import { MultiSelectItemComponent } from "./multiselect-item.component";

@NgModule({
  declarations: [MultiSelectComponent, MultiSelectItemComponent],
  imports: [
    CommonModule,
    GTypographyModule,
    GClickOutsideModule,
    MatIconModule,
    FormsModule,
    ReactiveFormsModule,
    GTemplateModule,
  ],
  exports: [MultiSelectComponent, MultiSelectItemComponent, GTemplateModule],
})
export class GMultiSelectModule {}
