import { Component, Input, OnInit, TemplateRef } from "@angular/core";

@Component({
  // tslint:disable-next-line:component-selector
  selector: "[g-tab-body]",
  template: `<ng-container *ngIf="active">
  <ng-template [ngTemplateOutlet]="content"></ng-template>
</ng-container>
`,
  styles: [],
  host: {
    class: "g-tab-body",
  },
})
export class TabBodyComponent implements OnInit {
  @Input() content: TemplateRef<void>;
  @Input() active: boolean;
  constructor() {}

  ngOnInit() {}
}
